.inputs-excepciones {
  margin-bottom: 15px;
}
.inputs-excepciones > div > div {
  padding-top: 5px;
}

.inputs-excepciones label {
  float: right;
  margin-right: 8px;
}

.text-area-modal {
  resize: none;
}

.input-claveProfesor-modal {
  z-index: 9 !important;
}

.inputs-excepciones {
  position: relative;
  z-index: 10;
}

.filter-input[disabled] {
  background-color: #f9f9f9;
}

.select-modal-container {
  width: 100%;
}

.clave-profesor-index0 {
  z-index: 0 !important;
}

.buttons-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0 15px 0;
}