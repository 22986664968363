.opciones-bar {
  height: 50px;
}

.nuevoUsuarioForm {
  display: flex;
  margin: 0 10px;
}

.nuevoUsuarioForm .avatarForm {
  width: 250px;
  text-align: center;
  position: relative;
}

.nuevoUsuarioForm label {
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin: 5px 15px;
}

.nuevoUsuarioForm input {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.nuevoUsuarioForm input::placeholder {
  font-family: "Roboto";
}

.avatarForm svg.avatar {
  height: 150px;
  width: 150px;
  border: 5px solid #1b1c20;
  border-radius: 100%;
  margin-top: 20px;
}

.editAvatar {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 140px;
  right: 20px;
  cursor: pointer;
  transition: all ease 0.5s;
}

.editAvatar:hover {
  filter: brightness(2.5);
}

.select-search-box::after {
  width: 0px;
  height: 0px;
  border-top: 10px solid #777;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  top: 10px;
  right: 8px;
  content: " ";
}

.select-search-box__options {
  margin: 0;
  padding: 0;
}

.select-search-box {
  position: relative;
}

.select-search-box-rol > div {
  border-radius: 25px;
}

.select-search-box-rol > div > div > div > svg {
  display: none;
}
.select-search-box-rol > div > div > span {
  display: none;
}

.select-search-box--multiple {
  border-radius: 4px;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  overflow: hidden;
}

input.select-search-box__search {
  line-height: 1;
}

.select-search-box--multiple .select-search-box__search {
  box-shadow: none;
}

.select-search-box--input .select-search-box__search {
  cursor: text;
}

.select-search-box__search:focus {
  cursor: text;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.select-search-box__select {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  background: #fff;
  overflow: auto;
  z-index: 100;
  min-height: 49px;
  border: 1px solid #ccc;
}

.select-search-box--multiple .select-search-box__select {
  display: block;
  position: static;
  border-top: 1px solid #eee;
  border-radius: 0;
  box-shadow: none;
}

.select-search-box__select--display {
  display: block;
}

.select-search-box__option {
  font-size: 14px;
  font-weight: 400;
  color: #616b74;
  padding: 5px 25px;
  border-top: 1px solid #eee;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
}

.select-search-box__option:first-child {
  border-top: none;
}

.select-search-box__option--hover,
.select-search-box__option:hover {
  background: #f4f7fa;
}

.select-search-box__option--selected {
  background: #54a0ff;
  color: #fff;
  border-top-color: #2184ff;
}

.select-search-box__option--selected:hover,
.select-search-box__option--selected.select-search-box__option--hover {
  background: #2184ff;
  color: #fff;
  border-top-color: #2184ff;
}

.select-search-box__group {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  position: relative;
}

.select-search-box__group-header {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 0 10px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
}
.error {
  color: red;
}
#contenedor-lista-permisos button {
  position: relative;
  left: calc(50% - 180px) !important;
  width: 180px;
  background-color: #1b1c20;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}
#contenedor-lista-permisos label {
  margin-left: 10px;
  margin-top: 20px;
  text-transform: capitalize;
}
#contenedor-lista-permisos input {
  margin-left: 40px;
  height: 15px;
  width: 15px;
  padding: 0px;
  margin-top: 3px;
  font-size: 30px;
}

#contenedor-lista-permisos {
  /* overflow-x: auto; */
  height: 150px;
  padding-inline: 15px;
  margin-top: 15px;
}
#contenedor-lista-permisos::-webkit-scrollbar {
  width: 5px;
  height: 5px !important;
  background-color: rgba(255, 255, 255, 0.2);
}
#contenedor-lista-permisos::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.6);
}
.los-permisos {
  /* text-transform: capitalize !important; */
  border-bottom: solid;
  border-width: 1px;
  border-collapse: all;
}
