.opcionesBar {
  margin: 5px 0;
  margin-bottom: 5px;
  min-height: 45px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.opcionesBar label {
  margin: 0 5px;
}

.opcionesBar span {
  margin: 0 5px;
}

.opcionesBar div > span {
  margin: 0 5px 0 0px;
}
.alertRoot {
  animation: miAnimacion2 3s linear 1;
  -webkit-animation: miAnimacion2 3s linear 1;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
  position: absolute;
  z-index: 100;
  margin-top: -3%;
  width: fit-content;
  margin-right:35px;
  padding: 12px;
  right: 0;
}