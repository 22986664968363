.MargenBuscar .SearchInputIconHidden{
  margin-top: 0px ;
}

.nuevoFormularioLabel span{
  font-size: 13px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.nuevoFormularioLabel.Pagos .NewModal-footer {
  text-align: center;
  padding: 15px 0 15px 0;
  position: absolute;
  width: 100%;
  bottom: none !important;
}