.NewModal {
  background-color: #fff;
  outline: 0;
  max-width: 800px;
  width: 90%;
  margin: 0 auto;
  margin-top: 5vh;
  padding: 10px;
  position: relative;
}

.NewModal h3 {
  margin: 0;
  background-color: #026eb7;
  color: #fff;
  padding: 10px 25px;
  font-size: 2em;
  font-weight: 400;
  border-bottom: 7px solid #f58a1d;
}

.NewModal-container {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  overflow-y: scroll;
  /* max-height: calc(100vh - 210px); */
}

.NewModal-footer {
  text-align: center;
  padding: 15px 0 15px 0;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.NewModal .gray-btn {
  background-color: #aca7a1;
  border: none;
  color: #fff;
  border-radius: 14px;
  width: 120px;
  padding: 6px 0;
  text-transform: uppercase;
  font-family: "Roboto";
  margin: 0 5px;
  cursor: pointer;
  transition: all ease 0.5s;
}

.NewModal .gray-btnAsistencia {
  background-color: #aca7a1;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  width: 150px;
  float: left;
  padding: 6px 0;
  transition: all ease 0.5s;
  margin-right: -170px;
  margin-left: 40px;
}

.NewModal .gray-btnAsistencia2 {
  background-color: #aca7a1;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  width: 150px;
  float: left;
  padding: 6px 0;
  transition: all ease 0.5s;
  margin-right: -170px;
  margin-left: 200px;
}

.NewModal .gray-btn:hover {
  opacity: 0.75;
}

.NewModal .black-btn {
  background-color: #026eb7;
  border: none;
  color: #fff;
  border-radius: 17px;
  text-transform: uppercase;
  font-family: "Roboto";
  margin: 0 5px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}

.NewModal .reportePre {
  margin-left: 40%;
  text-transform: none;
  font-size: 15px;
  padding: 10px 4px;
}

.NewModal .reporteDef {
  margin-left: 2%;
  width: 150px;
  text-transform: none;
  font-size: 15px;
  padding: 10px 4px;
}

.NewModal .trasladar {
  margin-left: 2%;
  width: 95px;
  text-transform: none;
  font-size: 15px;
  padding: 10px 4px;
}

.NewModal .envioBanner {
  margin-left: 2%;
  width: 115px;
  text-transform: none;
  font-size: 15px;
  padding: 10px 4px;
}

.NewModal .eliminarFDA {
  margin-left: 2%;
  width: 115px;
  text-transform: none;
  font-size: 15px;
  padding: 10px 4px;
  background-color: #f58a1d;
}

.NewModal .deshabilitar-button {
  background-color: #026eb7;
  border: none;
  color: #fff;
  border-radius: 17px;
  text-transform: uppercase;
  font-family: "Roboto";
  margin: 0 330px 0 -500px;
  width: 120px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}

.NewModal .active-button {
  left: 30px;
  margin-right: 10px;
  background-color: #f58a1d;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  width: 80px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
}

.NewModal .autorizarBtn {
  background-color: #f58a1d;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  width: 120px;
  padding: 6px 0;
  float: left;
  margin-left: 30px;
  margin-right: -150px;
  cursor: pointer;
  transition: all ease 0.5s;
}

.NewModal .delete-button {
  /* left: 30px; */
  background-color: #e72400;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  width: 110px;
  padding: 6px 0;
  cursor: pointer;
  /* cursor: pointer; */
  transition: all ease 0.5s;
}

.NewModal .reiniciar-button {
  left: 30px;
  background-color: #026eb7;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  width: 150px;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
  margin-right: 30px;
  margin-left: 10px;
}

.NewModal .cancelarRegistro-button {
  background-color: #026eb7;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  width: 150px;
  float: left;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
  margin-right: -170px;
  margin-left: 40px;
}
.NewModal .cancelarRegistro-button2 {
  background-color: #026eb7;
  border: none;
  color: #fff;
  border-radius: 10px;
  text-transform: uppercase;
  font-family: "Roboto";
  width: 150px;
  float: left;
  padding: 6px 0;
  cursor: pointer;
  transition: all ease 0.5s;
  margin-right: -170px;
  margin-left: 200px;
}

.NewModal .black-btn:hover {
  opacity: 0.75;
}

.NewModal > svg {
  position: absolute;
  color: #ddd;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.NewModal > svg:hover {
  color: #fff;
}
