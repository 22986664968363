.table-dash thead tr th {
  background-color: #026eb7;
  color: #fff;
  font-size: 1.15em;
  padding: 5px 10px;
  border-left: 2px solid #fff;
  white-space: nowrap;
}

.table-dash tbody tr td {
  padding: 3px 10px;
  border-left: 2px solid #fff;
}

.table-dash tbody tr:nth-child(even) {
  background-color: #eeeeef;
}

.HeadArrows {
  position: absolute;
  top: -2px;
  right: 0;
}

.HeadArrows svg {
  display: block;
}

.HeadArrows svg:nth-child(2) {
  margin-top: -15px;
}

.HeadArrows-con {
  position: absolute;
  top: -4px;
  right: -11px;
}

.HeadArrows-con svg {
  display: block;
}

.HeadArrows-con svg:nth-child(2) {
  margin-top: -15px;
}

.HeadCell {
  cursor: pointer;
  position: relative;
}

.contenedor-buttons {
  text-align: center;
}

.contenedor-buttons button {
  border-top: #026eb7 solid 1px;
  border-bottom: #026eb7 solid 1px;
  border-left: #026eb7 solid 1px;
  border-right: none;
  background-color: white;
  height: 40px;
  color: #026eb7;
  padding: 0 10px;
  cursor: pointer;
  transition: all ease 0.5s;
  margin: 0;
}

.contenedor-buttons button:focus {
  outline: 0;
}

.contenedor-buttons button:hover {
  background-color: #026eb7;
  color: white;
}

.contenedor-buttons button:last-child {
  border-right: #026eb7 solid 1px;
  border-radius: 0 10px 10px 0;
  padding: 0 15px;
}

.contenedor-buttons button:first-child {
  border-radius: 10px 0 0 10px;
  padding: 0 15px;
}

.contenedor-buttons button.active {
  background-color: #026eb7;
  color: white;
}
.pagina-inicio-btn {
  margin-right: 10px !important;
  border-right: #026eb7 solid 1px !important;
}
.pagina-fin-btn {
  margin-left: 10px !important;
  border-left: #026eb7 solid 1px !important;
}
.pagina-siguiente-btn {
  border-right: #026eb7 solid 1px !important;
}
