.nuevoUsuarioForm .MuiFormControl-root.date-picker,
.nuevoUsuarioForm-edit .MuiFormControl-root.date-picker {
  width: 100%;
  box-sizing: border-box;
  border-radius: 15px !important;
  background-color: #f9f9f9;
}

.nuevoUsuarioForm .date-picker .MuiInputBase-root input,
.nuevoUsuarioForm-edit .date-picker .MuiInputBase-root input {
  border: none;
  background-color: transparent;
  margin-top: 2px;
}

.nuevoUsuarioForm .date-picker .MuiButtonBase-root,
.nuevoUsuarioForm-edit .date-picker .MuiButtonBase-root {
  right: 5px;
  top: 0px;
  position: absolute;
  padding: 3px;
}
.select-modal-container{
  width: 100% !important;
}

.filter-input[disabled] {
  background-color: #e5e5e5 !important;
}
