.css-1r4vtzz {
    background-color: #f9f9f9 !important;
    border: 1px solid #ccc !important;
    width: 100% !important;
    box-sizing: border-box !important;
    padding: 5px 18px !important;
    outline: none !important;
    border-radius: 15px !important;
    box-shadow: none !important;
    margin-left: 0px !important;
  }
  
  .css-48ayfv {
    background-color: #f9f9f9 !important;
    border: 1px solid #ccc !important;
    width: 100% !important;
    box-sizing: border-box !important;
    padding: 5px 18px !important;
    outline: none !important;
    border-radius: 15px !important;
    box-shadow: none !important;
    margin-left: 0px !important;
  }
  
  .textEnd {
    text-align: end;
  }
  