.grid-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
  width: 320px;
  height: 125px;
}

.grid-container2 {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
  width: 240px;
  height: 125px;
}

.grid-container3 {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding-left: 10px;
  max-width: 620px;
  width: 100%;
  height: 125px;
}

.grid-sub-container {
  display: flex;
  align-items: center;
  height: 45px;
  width: 100%;
}

.grid-sub-container-2 {
  display: flex;
  jjustify-content: space-between;
  align-items: center;
  height: 45px;
  width: 100%;
}
