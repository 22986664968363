.LoginPage {
  width: 100vw;
  height: 10vh;
  box-sizing: border-box;
}

.LoginPage > div {
  height: 100%;
  display: flex;
  align-items: center;
}

.LoginBox {
  width: 400px;
  height: 300px;
  margin: 0 auto;
  text-align: center;
  padding: 30px;
}

.LoginBox > img {
  width: 85%;
  margin-top: 16vh;
}
.textSica {
  margin-top: -10vh;
  margin-left: 59vh;
  position: absolute;
  color: #026eb7;
  font-size: x-large;
  font-weight: 500;
}

.LoginBox label {
  display: block;
  text-align: left;
  font-size: 1.2em;
}

.LoginBox input {
  width: 100%;
  margin-bottom: 10px;
  padding: 4px 20px;
  box-sizing: border-box;
  border-radius: 15px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.input-error {
  border-color: #a50000 !important;
}

.error-message {
  color: #a50000;
}

.MuiCircularProgress-colorPrimary {
  color: #026eb7 !important;
}

.contenido {
  margin-inline: 60px;
  margin-bottom: 100px;
}

header {
  height: 80px;
  width: 100%;
  border-bottom: 10px solid #f58a1d;
}

header .logo {
  height: 100%;
  width: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  box-sizing: border-box;
  padding: 15px;
}

header .logo img {
  height: 115%;
}

header .logo p {
  font-size: 20px;
  margin-left: 10px;
  border-left: 1px solid #e72400;
  height: 40px;
  padding-top: 15px;
  padding-left: 10px;
}

header .page-title h1 {
  text-transform: uppercase;
  font-size: 2em;
  padding-top: 27px;
  margin: 0;
  float: left;
  margin-left: 40px;
  color: #026eb7;
}

.boton {
  display: flex;
}

.ingresar {
  margin-left: 3px;
  font-weight: 600;
}

@media (min-width: 960px) {
  .MuiGrid-grid-md-4 {
    flex-basis: 27% !important;
    max-width: 27% !important;
  }
}

.margen {
  margin-top: 100px !important;
}
