.SearchInput::placeholder {
  padding-left: 65px;
}

.SearchInput {
  width: 180px;
  height: 35px;
  border-radius: 15px;
  border: 1px solid #ccc;
  padding: 0 15px;
  font-size: 15px;
}

.SearchInput:focus::placeholder {
  visibility: hidden;
}

.SearchInput:focus {
  outline: none;
}

.SearchInputIconHidden {
  width: 100%;
  /* height: 35px; */
  border-radius: 15px;
  border: 1px solid #ccc;
  font-size: 15px;
  box-sizing: border-box;
  padding: 5px 15px;
}

.SearchInputIconHidden:focus::placeholder {
  visibility: hidden;
}

.SearchInputIconHidden:focus {
  outline: none;
}

.SearchInputIconHidden::placeholder {
  text-align: center;
}

.SearchInputIcon {
  position: absolute;
  width: 20px;
  margin-left: 55px;
  margin-top: 7px;
}
