.EditButton {
  background-color: #026eb7;
  color: white;
  border: none;
  padding: 3px 25px;
  border-radius: 10px;
  cursor: pointer;
  transition: all ease 0.5s;
}

.EditButton[disabled] {
  background-color: #acacac;
  cursor: initial;
}

.EditButton:hover {
  opacity: 0.8;
}

.NewButton {
  background-color: #f58a1d;
  color: white;
  border: none;
  padding: 5px 15px;
  border-radius: 17px;
  cursor: pointer;
  transition: all ease 0.5s;
  display: inline-flex;
  justify-content: center;
  align-self: center;
}

.NewButton span {
  font-size: 16px;
}

.NewButton:hover {
  opacity: 0.8;
}

.BlackButton {
  background-color: #f58a1d;
  color: white;
  border: none;
  padding: 7px 10px;
  border-radius: 17px;
  cursor: pointer;
  transition: all ease 0.5s;
  display: inline-flex;
  justify-content: center;
  align-self: center;
}

.BlackButton span {
  margin-top: 4px;
  margin-left: 5px;
  font-size: 16px;
}

.BlackButton:hover {
  opacity: 0.8;
}

.ButtonCancel {
  background-color: #999999;
  cursor: not-allowed;
  pointer-events: none;
}

.NewButton[disabled] {
  background-color: #acacac;
  cursor: initial;
}


.NewButton[disabled]:hover {
  opacity: 1;
}