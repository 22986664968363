.nuevoUsuarioForm .MuiFormControl-root.date-picker,
.nuevoUsuarioForm-edit .MuiFormControl-root.date-picker {
  width: 100%;
  box-sizing: border-box;
  border-radius: 15px !important;
  background-color: #f9f9f9;
}

.nuevoUsuarioForm .date-picker .MuiInputBase-root input,
.nuevoUsuarioForm-edit .date-picker .MuiInputBase-root input {
  border: none;
  background-color: transparent;
  margin-top: 2px;
}

.nuevoUsuarioForm .date-picker .MuiButtonBase-root,
.nuevoUsuarioForm-edit .date-picker .MuiButtonBase-root {
  right: 5px;
  top: 0px;
  position: absolute;
  padding: 3px;
}

.table_dias {
  margin-top: 10px;
  margin-bottom: 10px;
  border-collapse: collapse;
  width: 100%;
}

.table_dias,
td,
th {
  border: 3px solid;
  border-color: #d4d9de;
  text-align: center;
  font-size: 14px;

}

.table_background {
  background-color: #e5e5e5;
}

.bg_select {
  background-color: white;
}

hr.rounded {
  border-top: 5px solid #bbb;
  border-radius: 5px;
}
.text-area-modal{
  background-color: #F9F9F9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 7px 18px;
  border-radius: 15px;
  outline: none;
}