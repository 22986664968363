.inputsClasesEspeciales > div > div {
  padding-top: 5px;
}

.inputsClasesEspeciales label {
  float: right;
  margin-right: 8px;
}

.filterInput {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
  font-size: 16px;
}

.filterInput[disabled] {
  background-color: #dcdbdb;
}

.filterInputFecha > label {
  display: none;
}

.filterInputFecha > div {
  margin-top: 0 !important;
}

.filterInputFecha input {
  text-align: center;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  height: 27.5px;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.modalSectionName {
  margin: 0 0 20px;
  font-weight: 600;
}

.inputLabel {
  float: right;
}

.tableDias {
  margin-top: 10px;
  margin-bottom: 10px;
  border-collapse: collapse;
  width: 100%;
}

.tableDias th {
  background-color: #e9ecef;
  border: 3px solid;
  border-color: #d4d9de;
  text-align: center;
  font-size: 12px;
  padding: 8px;
}

.tableDias td {
  background-color: white;
  border: 3px solid;
  border-color: #d4d9de;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  padding: 8px;
}

.tipoSalon{
  width: 108%;
}

.materiaSelect{
  width: 101.5%
}

.diaSelect{
  width: 100%;
}