.opcionesBar {
  margin-bottom: 5px;
  min-height: 45px;
}

.opcionesBar > * {
  display: inline-block;
}

.opcionesBar label {
  margin: 0 5px;
}

.opcionesBar span {
  margin: 0 5px;
}

.opcionesBar div > span {
  margin: 0 5px 0 0px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-evenly;
}

.modalContentContainer {
  margin-top: -1%;
  padding: 25px;
}
.filterInput {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.filterInput[disabled] {
  background-color: #dcdbdb;
}

.filterInputFecha > label {
  display: none;
}

.filterInputFecha > div {
  margin-top: 0 !important;
}

.filterInputFecha input {
  text-align: center;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100% !important;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.gridStyle {
  text-align: center;
  align-items: center;
}
.divSecundario {
  background-color: #d3d0d0;
}

.filter_input_pro{
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
  resize: none;
}