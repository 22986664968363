.paper {
  margin: 0px 10px 30px !important;
  border: 1px solid #726d6d;
  border-radius: 5px;
}

.img {
  width: 190px;
  height: 100px;
  float: left;
  margin-right: 5px;
}

.text {
  margin-top: 20px;
  font-weight: 700;
  font-size: 10px;
}
