.class_spinner{
    position: absolute;
    height: 80vh;
    width: 100%;
    /* opacity: 0.5 !important; */
    /* background: rgb(228, 225, 225); */
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease-in-out;
  }
