.inputs-asignaciones > div > div {
  padding-top: 5px;
}

.inputs-asignaciones label {
  float: right;
  margin-right: 8px;
}

.filterinputfunction {
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
  text-align: center;
}

.filter-input {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.filter-input[disabled] {
  background-color: #f9f9f9;
}

.filter-input-fecha > label {
  display: none;
}

.filter-input-fecha > div {
  margin-top: 0 !important;
}

.filter-input-fecha > div::before {
  border-bottom: 0;
}

.filter-input-fecha {
  width: 100%;
}

.filter-input-fecha input {
  text-align: center;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 17px 20px;
  border-radius: 15px;
  outline: none;
}

.radio-form {
  margin-left: 50px;
}

.radio-form label {
  float: none;
  margin: 0 30px 0 10px;
}

.radio-form > fieldset {
  border: none;
}

.radio-form > fieldset > div {
  display: flex;
}

.radio-form > fieldset > div > div:nth-child(1) {
  margin-right: 30px;
}

.input-table {
  border-spacing: unset;
  margin: auto;
}

.input-table th,
.input-table td {
  border: 2px solid #d0d0d0;
  height: 40px;
}

.input-table th {
  background-color: #e9e9e9;
}

.input-table td > input {
  height: 100%;
  border: none;
  outline: none;
  width: 100px;
}

.input-table td:last-child > input {
  text-align: center;
  font-weight: bold;
}

.consult-tab {
  padding: 40px;
}

.consult-tab-option {
  display: flex;
  align-items: center;
}

.consult-tab-option label {
  margin-right: 20px;
  width: 150px;
}

.consult-tab-option_input {
  width: 100%;
}

.consult-tab-option_center {
  text-align: center;
}

.consult-tab-option_center label {
  display: block;
  margin-bottom: 14px;
}

.select-professor,
.select-professor .select-modal__control {
  width: 100%;
}

.input-select {
  margin-top: 10px;
}

.preassignment {
  padding: 20px;
}

.preassignment-filters {
  display: grid;
  grid-template-columns: 2.5fr 2.5fr 2.5fr 2fr 2fr;
  gap: 20px;
}

.preassignment-filters_option {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.preassignment-filters_option label {
  margin-right: 10px;
}

.preassignment-filters_option input[type="number"] {
  width: 90px;
}

.preassignment-filters_option-select {
  width: 100%;
}

.preassignment .preassignment-buttons {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.preassignment .preassignment-buttons button {
  margin-left: auto;
}

.preassignment-table {
  margin-top: 25px;
}

.table-arrow {
  background: #e7e7e7;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.table-arrow svg {
  stroke: rgb(38, 38, 38);
  width: 18px;
  fill: none;
  stroke-width: 2;
}

.table-delete {
  background: #ff1c1c;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.table-delete svg {
  stroke: #fff;
  width: 18px;
}
