.opcionesBar {
    margin: 5px 0;
    margin-bottom: 5px;
    min-height: 45px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .opcionesBar label {
    margin: 0 5px;
  }
  
  .opcionesBar span {
    margin: 0 5px;
  }
  
  .opcionesBar div > span {
    margin: 0 5px 0 0px;
  }
  
  .alignRight {
    text-align: right;
  }