.filter-input {
    height: 32px;
}

.table-dash thead tr th {
    padding: 0px 30px !important;
}

.css-1r4vtzz {
    background-color: #f9f9f9 !important;
    border: 1px solid #ccc !important;
    width: 100% !important;
    box-sizing: border-box !important;
    padding: 5px 18px !important;
    outline: none !important;
    border-radius: 15px !important;
    box-shadow: none !important;
    margin-left: 10px !important;
}

.css-48ayfv {
    background-color: #f9f9f9 !important;
    border: 1px solid #ccc !important;
    width: 100% !important;
    box-sizing: border-box !important;
    padding: 5px 18px !important;
    outline: none !important;
    border-radius: 15px !important;
    box-shadow: none !important;
    margin-left: 10px !important;
}
.buttonDownload {
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 5px;
  }
  .export-buttons{
    background-color: #f9f9f9 !important;
    border: 1px solid #ccc !important;
    box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    border-radius: 5px !important;
    padding: 5px;
  }