footer {
  background-color: #ddd;
  width: 100%;
  display: block;
  padding: 5px 20px;
  font-size: 13px;
  height: 25px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  z-index: 1;
}

footer span:first-child {
  float: left;
}

footer span:last-child {
  float: right;
}
