.opciones-bar {
  margin-left: 70%;
  margin-top: -2%;
  margin-bottom: 3%;
}

.flex-item {
  display: flex;
  align-items: center;
}

.inputs-asignaciones {
  margin: 10px 0;
}

textarea {
  font-family: inherit;
  font-size: 17px;
}

.bitacora {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.bitacora p {
  width: 100px;
  text-align: right;
  margin: 0 10px 0 0;
}

.bitacora span {
  background-color: #e5e5e5;
  border: 1px solid #ccc;
  margin-right: 10px;
  padding: 3px 7px;
  border-radius: 4px;
}

.bitacora .user {
  min-width: 230px;
}
