main {
    float: left;
    display: flex;
    flex-wrap: wrap;
    height: calc(100vh - 90px);
    overflow: auto;
    width: calc(100% - 220px);
    position: relative;
}

main .contenedor {
    margin: 0 30px;
    max-width: calc(100% - 60px);
    min-height: calc(100vh - 145px);
    flex-grow: 1;
}
