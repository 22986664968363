.opcionesBar {
  margin-bottom: 5px;
  min-height: 45px;
}

.opcionesBar > * {
  display: inline-block;
}

.opcionesBar label {
  margin: 0 5px;
}

.opcionesBar span {
  margin: 0 5px;
}

.opcionesBar div > span {
  margin: 0 5px 0 0px;
}

.modalContentContainer {
  padding: 25px;
}

.modalContentContainer input {
  margin-top: 10px;
}

.modalContentContainer label {
  margin-top: 10px;
  display: block;
}

.select-search-box > div > div > div > svg {
  display: none;
}
.select-search-box > div > div > span {
  display: none;
}

.select-search-box__option {
  padding-left: 0px;
  padding-right: 0px;
}

.filterInput {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  padding: 5px 18px;
  border-radius: 15px;
  outline: none;
}

.filterInput[disabled] {
  background-color: #dcdbdb;
}
