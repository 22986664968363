.opcionesBar {
    margin-bottom: 5px;
    min-height: 45px;
  }
  
  .opcionesBar > * {
    display: inline-block;
  }
  
  .opcionesBar label {
    margin: 0 5px;
  }
  
  .opcionesBar span {
    margin: 0 5px;
  }
  
  .opcionesBar div > span {
    margin: 0 5px 0 0px;
  }
  
  .buttonsContainer {
    display: flex;
    justify-content: space-evenly;
  }
  .buttonsContainer button {
    margin: 0px 8px;
  } 
  
  .modalContentContainer {
    padding: 25px;
  }
  .filterInput {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
    padding: 5px 18px;
    border-radius: 15px;
    outline: none;
  }
  
  .filterInput[disabled] {
    background-color: #dcdbdb;
  }
  
  .filterInputFecha > label {
    display: none;
  }
  
  .filterInputFecha > div {
    margin-top: 0 !important;
  }
  
  .filterInputFecha input {
    text-align: center;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    width: 100% !important;
    box-sizing: border-box;
    padding: 5px 18px;
    border-radius: 15px;
    outline: none;
  }
  
  .gridStyle {
    text-align: center;
    align-items: center;
  }
  .divSecundario {
    background-color: #d3d0d0;
  }

  .indicadores{
    /* height: 22%; */
    /* width: 96%; */
    margin-top: 1%; 
    /* margin-left: 2%; */
    border: 1px solid #a0a0a0;
    background-color: #eaeaea;
    align-items: center;
  }

  .indicadoresTitle{
    font-weight: 600;
    font-size: 20px;
    float: left;
    margin-top: 2%;
  }

  .divSubtitle1{
    border: 1px #989696;
    background-color: #e2a90e;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.5%;
    /* margin-left: 1%; */
    width: 41.3%;
    height: 12%;
  }
  .divSubtitle2y3{
    border: 1px #989696;
    background-color: #e2a90e;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.5%;
    margin-left: 1%;
    width: 22.5%;
    height: 12%;
  }

  .subtitles{
    font-weight: 600;
    text-decoration: solid;
    font-size: 18px;
  }

  .buttons{
    padding: 10px 20px;
  }
  