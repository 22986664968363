.tabs-list {
  display: flex;
}
.NewModal-container > .tabs-list {
  margin-top: 10px;
}
.tab {
  border-radius: 20px 20px 0 0;
  padding: 5px 20px;
  cursor: pointer;
  transition: all ease 0.2s;
}

.tab:hover {
  z-index: 99;
}

.tab:nth-child(odd) {
  background-color: #aca7a1;
  color: #fff;
}

.tab:nth-child(even) {
  background-color: #f58a1d;
  color: #fff;
  margin-left: -10px;
}

.tab.active {
  background-color: #026eb7;
  z-index: 9;
  color: white;
}

.tab-content {
  height: calc(100% - 15px);
  border-left: 1px solid #aca7a1;
  border-right: 1px solid #aca7a1;
  border-bottom: 1px solid #aca7a1;
  border-radius: 0 10px 10px 10px;
  overflow: hidden;
  padding: 0;
}
.tab-content-modal {
  height: calc(100% - 75px);
  border-left: 1px solid #aca7a1;
  border-right: 1px solid #aca7a1;
  border-bottom: 1px solid #aca7a1;
  border-radius: 0 10px 10px 10px;
  overflow: hidden;
  padding: 0;
}

.tab-header {
  height: 15px;
}
